import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import HomePage from "./components/routes/HomePage";
import AppsPage from "./components/routes/AppsPage";
import SupportPage from "./components/routes/SupportPage";
import PrivacyPage from "./components/routes/PrivacyPage";
import ErrorPage from "./components/common/ErrorPage";
import Header from "./components/common/Header";

import "./App.css";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Header />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <HomePage />,
        },
        {
          path: "apps",
          element: <AppsPage />,
        },
        {
          path: "support",
          element: <SupportPage />,
        },
        {
          path: "privacy",
          element: <PrivacyPage />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
