import React from "react";

const HomePage = () => {
  return (
    <div className='container'>
      <p className='title'>AbbitSoft</p>
      <p className='sub-title'>Innovative web- og mobilsoftwareløsninger</p>
      <p className='description'>
        AbbitSoft er en softwareudviklingsvirksomhed, der har specialiseret sig i at skabe
        innovative og effektive softwareløsninger til virksomheder i alle størrelser. Vi er
        dedikerede til at hjælpe vores kunder med at strømline deres drift, øge effektiviteten og
        skabe vækst ved hjælp af banebrydende teknologi. Vores team af erfarne udviklere og
        softwareingeniører arbejder tæt sammen med vores kunder for at forstå deres unikke behov og
        skræddersy vores løsninger til at opfylde disse krav. Vi er forpligtet til at levere
        brugervenlig software af høj kvalitet, der er let at navigere og tilpasse til vores kunders
        skiftende behov. Med AbbitSoft kan virksomheder stole på en omfattende, pålidelig og
        fleksibel softwareløsning, der hjælper dem med at holde sig foran konkurrenterne.
      </p>
    </div>
  );
};

export default HomePage;
