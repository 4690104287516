import React from "react";
import { NavLink, Outlet } from "react-router-dom";

const Header = () => {
  const activeStyle = { color: "#563BEF" };

  return (
    <>
      <nav>
        <NavLink
          to='/'
          className='nav-item'
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          Hjem
        </NavLink>
        <NavLink
          to='/apps'
          className='nav-item'
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          Vores apps
        </NavLink>
        <NavLink
          to='/support'
          className='nav-item'
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          Kontakt
        </NavLink>
        <NavLink
          to='/privacy'
          className='nav-item'
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          Persondata
        </NavLink>
      </nav>
      <Outlet />
    </>
  );
};

export default Header;
