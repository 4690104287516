import React from "react";

const PrivacyPage = () => {
  return (
    <div className='container'>
      <p className='description-title'>1. Introduktion </p>
      <p className='description'>
        Denne Persondatapolitik beskriver, hvordan Bitdriver (”vi”, ”os”, ”vores”) håndterer dine
        personoplysninger. Vi lægger stor vægt på, at du er tryg ved at anvende vores service.
        Persondatapolitikken indeholder information om vores indsamling og brug af personoplysninger
        samt en beskrivelse af dine rettigheder. Enhver behandling af dine oplysninger sker i
        overensstemmelse med den til enhver tid gældende lovgivning.
      </p>
      <p className='description-title'>2. Dataansvarlig</p>
      <p className='description'>
        Bitdriver er drevet og ejet af AbbitSoft. Den juridiske enhed, som er ansvarlig for
        behandlingen af dine personoplysninger, er: AbbitSoft Vores CVR nummer er DK 36431954, og vi
        har adresse på Niverød Kongevej 16, 2990 Nivå. Du er velkommen til at skrive til os på{" "}
        <a className='description' href='mailto:support@abbitsoft.dk'>
          support@abbitsoft.dk
        </a>
        , hvis du har spørgsmål.
      </p>
      <p className='description-title'>3. Ændringer </p>
      <p className='description'>
        Vi forbeholder os til enhver tid ret til at opdatere denne Persondatapolitik.
      </p>
      <p className='description-title'>4. Hvilke oplysninger indsamler Bitdriver? </p>
      <p className='description'>
        For at kunne besvare dine henvendelser og korrespondere med dig, indsamler vi de
        personoplysninger, som du opgiver, når du er i kontakt med os, f.eks. via e-mail, sociale
        medier eller telefonisk: Navn, e-mailadresse. Oplysninger om e-mails, og anden digital
        kommunikation, som vi sender til dig, som du åbner herunder de links deri, som du klikker
        på. Som bruger af de sociale medier, som eksempelvis Facebook og Instagram er vores
        virksomhedssider underlagt Facebooks persondatapolitik. Vi indsamler ikke selv yderligere
        persondata om de besøgende på vores virksomhedssider på disse medier end det udbyderen,
        Facebook, gør. Du kan læse mere om Facebooks persondatapolitik:{" "}
        <a href='https://www.facebook.com/business/gdpr' target='_blank' rel='noreferrer'>
          https://www.facebook.com/business/gdpr
        </a>
      </p>
      <p className='description-title'>5. Periode for opbevaring </p>
      <p className='description'>
        Oplysningerne opbevares i overensstemmelse med gældende lovgivning, og vi sletter
        oplysningerne, når de ikke længere er nødvendige. Perioden for opbevaring afhænger af den
        type behandling, der foretages, samt karakteren af oplysningerne, og er fastsat i forhold
        til de forpligtelser, som mobilapp'en er underlagt i henhold til gældende lovgivning, samt
        til sikring af dokumentation. Vi er også forpligtet til at overholde bestemmelserne i
        bogføringslovgivningen, og relevante oplysninger i relation hertil, kan derfor ikke slettes
        før lovgivningens frister udløber.
      </p>
      <p className='description-title'>6. Hvor lagres dine personoplysninger </p>
      <p className='description'>
        Dine personoplysninger gemmes på et sikret netværk, og kan kun tilgås med specielle
        rettigheder.
      </p>
      <p className='description-title'>7. Dine rettigheder </p>
      <p className='description'>
        Du har efter databeskyttelsesforordningen en række rettigheder i forhold til vores
        behandling af oplysninger om dig. Hvis du vil gøre brug af dine rettigheder, skal du
        kontakte os. Ret til at se oplysninger (indsigtsret) Du har ret til at få indsigt i de
        oplysninger, som vi behandler om dig, samt en række yderligere oplysninger. Ret til
        berigtigelse (rettelse) Du har ret til at få urigtige oplysninger om dig selv rettet. Ret
        til sletning I særlige tilfælde har du ret til at få slettet oplysninger om dig, inden
        tidspunktet for vores almindelige generelle sletning indtræffer. Ret til begrænsning af
        behandling Du har visse tilfælde ret til at få behandlingen af dine personoplysninger
        begrænset. Hvis du har ret til at få begrænset behandlingen, må vi fremover kun behandle
        oplysningerne – bortset fra opbevaring – med dit samtykke, eller med henblik på at retskrav
        kan fastlægges, gøres gældende eller forsvares, eller for at beskytte en person eller
        vigtige samfundsinteresser. Ret til indsigelse Du har i visse tilfælde ret til at gøre
        indsigelse mod vores eller lovlige behandling af dine personoplysninger. Du kan også gøre
        indsigelse mod behandling af dine oplysninger til direkte markedsføring. Ret til at trække
        et samtykke tilbage Hvis du som led i behandlingen af dine personoplysninger har afgivet et
        samtykke hertil, har du til enhver tid ret til at trække dit samtykke tilbage. Hvis du
        vælger at trække dit samtykke tilbage, påvirker det ikke lovligheden af vores behandling af
        dine personoplysninger på baggrund af dit tidligere meddelte samtykke og op til tidspunktet
        for tilbagetrækningen. Hvis du tilbagetrækker dit samtykke, har det derfor først virkning
        fra dette tidspunkt. Du kan læse mere om dine rettigheder i Datatilsynets vejledning om de
        registreredes rettigheder, som du finder på www.datatilsynet.dk
      </p>
      <p className='description-title'>8. Kontakt og klageadgang </p>
      <p className='description'>
        Hvis du har spørgsmål, kommentarer eller klager til Bitdriver’ s behandling af dine
        personoplysninger, kan der rettes henvendelse til: AbbitSoft, CVR. DK36431954, Niverød
        Kongevej 16, 2990 Nivå,{" "}
        <a className='description' href='mailto:support@abbitsoft.dk'>
          support@abbitsoft.dk
        </a>
        . Eventuelle klager kan også rettes til Datatilsynet. Den aktuelle kontaktadresse kan findes
        på: www.datatilsynet.dk
      </p>
    </div>
  );
};

export default PrivacyPage;
