import React from "react";

const AboutPage = () => {
  return (
    <div className='container'>
      <p className='description'>
        Du er altid velkommen til at kontakte Bitdriver. Hos Bitdriver går vi meget op i service,
        kvalitet og kundetilfredshed.
      </p>
      <p className='description'>
        Derfor er vi i løbende dialog med brugerne af Bitdriver for at sikre os at der arbejdes på
        forbedringer af kvalitet og service. Derfor vil al feedback fra brugerne hjælpe os med
        forbedringer.
      </p>
      <p className='description'>
        Vi bestræber på at besvare alle henvendelser så hurtigt som muligt. Du er velkommen til at
        skrive til os
        <a href='mailto:support@abbitsoft.dk'> support@abbitsoft.dk</a>
      </p>
    </div>
  );
};

export default AboutPage;
