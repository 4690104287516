import React from "react";
import { Outlet } from "react-router-dom";
import bitdriverIOS from "../../assets/bitdrive_ios.png";
import bitdriverAndroid from "../../assets/bitdrive_android.png";
import bitbookings from "../../assets/bitbookings-createbooking.png";
import bitbookmanager from "../../assets/bitbookmanager-viewbooking.png";

const AppsPage = () => {
  return (
    <div className='container'>
      <p className='title'>BitDriver - React Native Applikation</p>
      <p className='description'>
        Bitdriver er en mobilapplikation, der kører på både iOS og Android. Det hjælper brugerne med
        at bestemme afstanden mellem to geografiske placeringer. Det primære mål med appen er at
        give en nøjagtigt og brugervenligt værktøj til måling af den tilbagelagte afstand mellem to
        punkter. Bitdriver er designet til at være brugervenligt og let at navigere, hvilket gør det
        til et værdifuldt værktøj til enhver, der har brug for at måle afstanden mellem to steder.
        Uanset om du er en rejsende, en leveringschauffør eller en virksomhedsejer vil BitDriver
        hjælpe dig.
      </p>
      <div className='image-container'>
        <img src={bitdriverIOS} className='image' alt='bitdriver-ios' />
        <img src={bitdriverAndroid} className='image' alt='bitdriver-android' />
      </div>
      <p className='title'>BitBook - React Native Booking System</p>
      <p className='description'>
        BitBook er en omfattende og alsidig bookingplatform designet specielt til virksomheder. Det
        fungerer problemfrit på både iOS og Android og tilbyder en brugervenlig oplevelse for både
        kunder og ledere. Platformen består af to forskellige applikationer: en brugerapp og en
        manager-app. Brugerappen giver kunderne mulighed for nemt at oprette bookinger, mens
        manager-appen giver virksomheder fuld kontrol over deres bookinger ved at give dem mulighed
        for nemt at acceptere eller afvise anmodninger. Ved at kombinere bekvemmeligheden ved
        brugervenligt design med kraften i problemfri administration er BitBook den ideelle løsning
        for virksomheder, der ønsker at strømline deres bookingprocesser.
      </p>
      <div className='image-container'>
        <img src={bitbookings} className='image' alt='bitbookings' />
        <img src={bitbookmanager} className='image' alt='bitbookmanager' />
      </div>
      <Outlet />
    </div>
  );
};

export default AppsPage;
